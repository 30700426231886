<template>
  <b-row>
    <b-col cols="12" lg="12">
        <b-card class="w-100 mb-4" no-body>
            <div class="p-35">
                <div class="d-flex align-items-start">
                    <div>
                    <h4 class="card-title mb-1">Purchase order to add CheckIn</h4>
                    <b-card-text class="text-muted">
                        To use add border-bottom py-3 class in the every row styling.
                    </b-card-text>
                    </div>
                </div>
                </div>
                <hr class="m-0" />
                <b-card-body class="bg-light">
                <!-- part 2 start -->
                <h4 class="card-title">Purchase Order Details</h4>
                <b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="interest"> Supplier </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-select
                        v-model="form.supplier_id"
                        :options="suppliers"
                    ></b-form-select>
                    </b-col>
                </b-row>
                <b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> Purchase Ref </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        id="purchaseref"
                        type="text"
                        placeholder="Purchase Ref"
                        v-model="form.purchase_ref"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="payment"> Payment Method </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        id="payment"
                        type="text"
                        placeholder="Payment Method"
                        v-model="form.payment_method"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="paymentref"> Payment Ref </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        id="paymentref"
                        type="text"
                        placeholder="payment Ref"
                        v-model="form.payment_ref"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <!--<b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="file"> Attach File </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-file id="file"></b-form-file>
                    </b-col>
                </b-row>-->
                </b-card-body>
                <!--
                <div class="p-35 border-top">
                <div class="btn-grp text-right">
                    <b-button pill variant="primary" class="px-4">Save</b-button>
                    <b-button pill variant="danger" class="px-4">Cancel</b-button>
                </div>
                </div>
                -->
        </b-card>
    </b-col>
    <b-col cols="12" lg="12">
        <b-card>
        <div v-if="!$route.params.id" class="d-flex">
            <b-col cols="12" lg="2" class="mb-3">
            <h6>Name</h6>
            <Select2 
              v-model="defaultItem.product_code" 
              :options="productOptions"
            />
            </b-col>
            <b-col cols="12" lg="2" class="mb-3">
            <h6>Quantity</h6>
            <b-form-input
                v-model="defaultItem.qty"
                placeholder="0"
                name="name-group"
                type="number"
                @change="updateStockValue()"
            ></b-form-input>
            </b-col>
            <b-col cols="12" lg="2" class="mb-3">
            <h6>Stock Value {{ currentProduct? "("+currentProduct.stock_unit+")":"" }}</h6>
            <b-form-input
                v-model="defaultItem.stock_value"
                placeholder="0"
                name="name-group"
            ></b-form-input>
            </b-col>
            <!--<b-col cols="12" lg="6" class="mb-3">
            <h6>Purchase Date</h6>
            <b-form-datepicker
                id="example-datepicker"
                v-model="defaultItem.pdate"
                :date-format-options="{
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                }"
                class="mb-2"
            ></b-form-datepicker>
            </b-col>-->
            <b-col cols="12" lg="2" class="mb-3">
            <h6>Batch No</h6>
            <b-form-input
                v-model="defaultItem.batch"
                placeholder="Batch No"
                name="name-group"
            ></b-form-input>
            </b-col>
            <b-col cols="12" lg="2" class="mb-3">
            <h6>Expiry Date</h6>
            <b-form-datepicker
                id="example2-datepicker"
                v-model="defaultItem.edate"
                :date-format-options="{
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                }"
                class="mb-2"
            ></b-form-datepicker>
            </b-col>
            <b-col>
            <b-button class="mt-4 mr-4" variant="info" @click="resetOptions">Reset</b-button>
            <b-button class="mt-4 ml-auto" variant="success" @click="addProduct()">+ Add Product</b-button>
            </b-col>
        </div>
        <b-table
        hover
        bordered
        responsive
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        class="contact-table"
        >
        <!-- A custom formatted column -->
        <template #cell(product_name)="data">
            <div class="d-flex align-items-center">
            <h5 class="mx-2 mb-0">{{ data.item.product_name }}</h5>
            </div>
        </template>
        <!-- A custom formatted column -->
        <template #cell(product_quantity)="data">
            <b-badge :variant="info">{{ data.item.product_quantity }}</b-badge>
        </template>
        <!-- A custom formatted column -->
        <template #cell(actions)="data">
            <a
            v-if="!$route.params.id"
            class="mx-1 cursor-pointer text-danger"
            @click="deleteItem(data.item.id)"
            ><feather type="trash-2" class="text-danger feather-sm"></feather
            ></a>
        </template>
        </b-table>
        <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="center"
        ></b-pagination>
        <!--<b-modal
        ref="add-modal"
        id="addModal"
        hide-header
        @ok="addProduct"
        @cancel="close"
        ok-title="Save"
        >
        <h4 class="mb-4">{{ formTitle }}</h4>
        <b-row>
            
        </b-row>
        </b-modal>-->
    </b-card>
    </b-col>
    <b-col cols="12" lg="12">
        <b-card class="w-100 mb-4" no-body>
            <div class="p-35">
                <div class="d-flex align-items-start">
                    <div>
                    <h4 class="card-title mb-1">Click save to finalize CheckIn</h4>
                    <b-card-text class="text-muted">
                        You will have 24Hrs to edit this purchase to edit
                    </b-card-text>
                    </div>
                </div>
                </div>
                <hr class="m-0" />
                <b-card-body class="bg-light">
                <!-- part 2 start -->
                <!--<h4 class="card-title">Purchase Order Totals</h4>
                
                <b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> Net Total </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        v-model="form.net_total"
                        id="company"
                        type="text"
                        placeholder="Net Total"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> Sales Tax </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        v-model="form.sales_tax"
                        id="company"
                        type="text"
                        placeholder="Sales Tax"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="aboutcomp"> Grand Total </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        v-model="form.grand_total"
                        id="aboutcomp"
                        type="text"
                        placeholder="Grand Total"
                    ></b-form-input>
                    </b-col>
                </b-row>-->
                </b-card-body>
                <div class="p-35 border-top">
                <div v-if="!$route.params.id" class="btn-grp text-right">
                    <b-button pill variant="primary" class="px-4" @click="saveCheckIn()">Save</b-button>
                    <!--<b-button pill variant="danger" class="px-4">Cancel</b-button>-->
                </div>
            </div>
        </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: "CheckIn",
  data: () => ({
    form: {
      supplier_id : null,
      supplier_name : null,
      purchase_ref : "",
      payment_method : "",
      payment_ref : "",
      purchase_by : "WH Manager",
      datetime : null,
      status : 1
    },
    currentProduct: null,
    selectedProducts: [],
    sortBy: "product_code",
    fields: [
      {
        key: "product_code",
        label: "Product SKU",
      },
      {
        key: "product_name",
        label: "Product Name",
      },
      {
        key: "qty",
        label: "Quantity",
      },
      {
        key: "actions",
        label: "Actions",
      }
    ],
    items: [],
    products: [],
    productOptions: [],
    filter: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    url: null,
    editedIndex: -1,
    defaultItem:  {
      checkin_id: null,
      product_code: null,
      product_name: null,
      qty: null,
      stock_value: null,
      pdate: moment().format(),
      edate: null,
      batch: null
    },
    suppliers: []
  }),
  created() {
  },
  mounted() {
    if(this.$route.params.id){
      this.loadPurchase(this.$route.params.id);
    }
    this.loadProducts();
    this.loadSuppliers();
  },
  methods: {
    ...mapActions(["getSuppliers", "getProducts","getCheckinProducts", "addCheckin", "addCheckinItem","getCheckin","updateInventory"]),
    loadPurchase(id){
      this.getCheckin(id).then(res => {
        this.form = res.data[0];
        console.log(this.form);
        this.loadPurchaseProducts();
      })
    },
    loadProducts(){
      this.getProducts().then((res) => {
        this.products = res.data;
        this.productOptions = res.data.map(p => {
          return { text:p.product_code+":"+p.product_name, id:p.product_code }
        });
        // Set the initial number of items
        //this.totalRows = this.items.length;
      })
    },
    loadPurchaseProducts(){
      this.getCheckinProducts(this.form.checkin_id).then((res) => {
        this.items = res.data;
        // Set the initial number of items
        this.totalRows = this.items.length;
      })
    },
    loadSuppliers(){
      this.getSuppliers().then((res) => {
        this.suppliers = res.data.map(s => {
          return { value:s.supplier_id, text:s.company_name }
        });
      })
    },
    addItem() {
      this.$refs["add-modal"].show();
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.items.splice(index, 1);
    },
    close() {
      this.$refs["edit-modal"].hide();
    },
    resetOptions() {
      this.defaultItem = {
        checkin_id: null,
        product_code: null,
        product_name: null,
        qty: null,
        stock_value: null,
        pdate: moment().format(),
        edate: null,
        batch: null
      }
    },
    selectProduct(){
      this.currentProduct = this.products.find(p => { return p.product_code === this.defaultItem.product_code });
      this.defaultItem.product_name = this.currentProduct.product_name;
    },
    addProduct() {
      this.selectProduct();
      this.items.push(this.defaultItem);
      this.defaultItem = {
        checkin_id: null,
        product_code: null,
        product_name: null,
        qty: null,
        stock_value: null,
        pdate: moment().format(),
        edate: null,
        batch: null
      }
    },
    updateStockValue(){
      this.selectProduct();
      this.defaultItem.stock_value = this.defaultItem.qty * this.currentProduct.unit_value;
    },
    saveCheckIn(){
      this.form.supplier_name = this.suppliers.find(s => { return s.value === this.form.supplier_id }).text;
      this.addCheckin(this.form).then(res => {
        let chkinid = res.data.id;
        this.items.forEach((item, n) => {
          item.checkin_id = chkinid;
          this.addCheckinItem(item).then(res => {
            let prod = this.products.find(p => { return item.product_code === p.product_code });
            this.updateInventory({ id: prod.product_id, qty: item.qty }).then(resp => {
              console.log(resp.data.id);
            })
            console.log(res.data.id);
          })
          if(this.items.length === n+1){
            this.$router.push({name: "List CheckIns"})
          }
        }); 
      })
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Product" : "Edit Product";
    },
    rows() {
      return this.items.length;
    },
  },
  watch: {},
};
</script>

<style>
.contact-table td {
  vertical-align: middle;
}
</style>
